import styled from "styled-components"

const Title = styled.h2`
  font-weight: normal;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 64px;
  }
`

export default Title
