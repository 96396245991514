import styled from "styled-components"

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 15px 1fr;
  grid-gap: 9px;
  align-items: center;
  margin: 15px 0 32px 22px;
`

export default Container
