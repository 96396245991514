import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px 32px;
  align-items: center;
  margin: 32px auto 0;
  position: relative;
`

export default Wrapper
