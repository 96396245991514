import React, { useContext } from "react"
import Context from "../../../store/Context"

//Styled components imports
import Wrapper from "./Wrapper"
import NavLinks from "./NavLinks"
import NavLink from "./NavLink"
import Link from "./Link"

const Submenu = props => {
  const { state } = useContext(Context)

  return (
    <Wrapper>
      <NavLinks>
        <NavLink>
          <Link
            to="/moje-rezervacie"
            active={state.path === "/moje-rezervacie" ? "true" : undefined}
          >
            Moje rezervácie
          </Link>
        </NavLink>
        <NavLink>
          <Link
            to="/moje-udaje"
            active={state.path === "/moje-udaje" ? "true" : undefined}
          >
            Moje údaje
          </Link>
        </NavLink>
        <NavLink>
          <Link to="/">Odhlásiť</Link>
        </NavLink>
      </NavLinks>
    </Wrapper>
  )
}

export default Submenu
