import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: ${props =>
    props.active
      ? props.theme.colors.primary.green
      : props.theme.colors.text.white};

  @media (max-width: 350px) {
    font-size: 12px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 18px;
  }
`

export default Link
