import styled from "styled-components"

const Wrapper = styled.div`
  position: static;
  grid-column: 1/3;
  grid-row: 1/2;
  margin: 0 -10px;
  justify-self: end;
  width: calc(100% + 20px);
  max-width: 350px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    max-width: 450px;
    margin: 0;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    width: 500px;
    position: absolute;
    right: 10px;
    top: 0;
  }
`

export default Wrapper
