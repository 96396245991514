import React from "react"

//React components imports
import Layout from "../components/Layout"
import SignInForm from "../components/SignInForm"
import PageTitle from "../components/PageTitle"

import ReservationText from "../components/ReservationText"

const SignInPage = props => {
  return (
    <Layout>
      {/* <PageTitle subtitle="Prihlásenie">Rezervácia</PageTitle>
      <SignInForm /> */}
      <ReservationText />
    </Layout>
  )
}

export default SignInPage
