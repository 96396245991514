import React from "react"

//React components imports
import Submenu from "./Submenu"

//Styled components imports
import Wrapper from "./Wrapper"
import Title from "./Title"
import Line from "./Line"
import Container from "./Container"
import Subtitle from "./Subtitle"

const PageTitle = props => {
  const loggedIn = true

  return (
    <>
      <Wrapper>
        <Title>{props.children}</Title>
        <Line top="10px" tablet />
        {props.children === "Rezervácia" && loggedIn && <Submenu />}
      </Wrapper>
      {props.subtitle && (
        <>
          <Container>
            <Line vertical />
            <Line />
            <Subtitle>{props.subtitle}</Subtitle>
          </Container>
        </>
      )}
    </>
  )
}

export default PageTitle
