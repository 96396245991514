import styled from "styled-components"

const NavLinks = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  justify-items: end;
`

export default NavLinks
