import styled, { css } from "styled-components"

const verticalLine = css`
  position: absolute;
  width: 28px;
  left: -13px;
  top: 0px;
  transform: rotate(90deg);
`

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: ${props => props.theme.colors.primary.green};
  margin-top: ${props => props.top};

  ${props => props.tablet && "display: none"};
  ${props => props.vertical && verticalLine};

  @media (${props => props.theme.mediaQueries.tablet}) {
    display: inline-block;
  }
`

export default Line
