import styled from "styled-components"

const StyledForm = styled.form`
  display: grid;
  grid-gap: 24px;
  max-width: 250px;
  margin: auto;
  justify-items: center;
`

export default StyledForm
